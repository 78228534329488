<template>
  <v-container fluid class="pt-6 mt-6">
    <v-row justify="center">
      <v-col md="6" lg="5">
        <div class="quota-header">
          <div class="quota-header__back">
            <v-btn fab dark color="info" x-small text @click="goBack">
              <IconPictoAarrowsLeft />
            </v-btn>
          </div>
          <div class="quota-header__title">
            Vous pouvez rajouter des documents dans cette phase de finalisation
          </div>
          <div class="quota-header__description">
            Documents divers (plan et informations techniques ) et des
            Photographies du bien à rénover
          </div>
        </div>
        <div class="quota-file">
          <AddFile
            text="Téléchargez vos documents"
            outlined
            @addingFilesComplete="addNewFiles"
          />
        </div>
        <div class="quota-comment">
          <div class="quota-comment__title">Rajoutez des commentaires</div>
          <div class="quota-comment__textarea">
            <v-textarea
              v-model="message"
              outlined
              type="text"
              dense
              placeholder="Faites vos commentaires, detail spécifiques et autres remarques sur votre projet"
            ></v-textarea>
          </div>
        </div>
        <div class="quota-action">
          <v-btn color="info" depressed :loading="loading" @click="submit">
            VALIDER
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { MessageService } from "@/services/messages.service.js";
export default {
  name: "QuotaAddDocument",
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
    AddFile: () => import("../AddFile/AddFile.vue"),
  },
  data() {
    return {
      loading: false,
      message: "",
      messageId: null,
    };
  },
  methods: {
    addNewFiles() {
      this.$store.commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        this.$t("snackbar.success.update")
      );
    },
    submit() {
      this.loading = true;
      this.createMessage()
    },
    createMessage() {
      MessageService.createMessage({
        message: this.message,
        quota_id: this.$store.getters["quota/getCurrentQuotaId"],
        project_id: this.$store.getters["project/getCurrentProjectId"],
      })
        .catch((e) => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => {
          this.loading = false;
          this.$router.push({ name: "QuotaPreValidation" });
        });
    },
    goBack() {
      this.$router.push({
        name: "QuotaSum",
        params: {
          quotaId: this.$route.params.quotaId,
        },
      });
    },
  },
};
</script>

<style src="./QuotaPreview.scss" lang="scss" scoped></style>